



























import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Inconclusive extends Vue {
  private restart (): void {
    const redirectUrl = localStorage.getItem('redirectUrl') as string
    const caseId = localStorage.getItem('caseId') as string
    const serialNumber = localStorage.getItem('serialNumber') as string

    if (redirectUrl !== 'undefined' && serialNumber !== 'undefined' && caseId !== 'undefined') {
      window.location.href = redirectUrl + '?case_id=' + caseId + '&serial_number=' + serialNumber
    }
    // this.$router.push({ name: 'welcome' })
  }
}
